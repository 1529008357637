export const shortHandDays = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

export const minsToReadable = [
  { value: 1, display: '1 min' },
  { value: 2, display: '2 mins' },
  { value: 3, display: '3 mins' },
  { value: 4, display: '4 mins' },
  { value: 5, display: '5 mins' },
  { value: 6, display: '6 mins' },
  { value: 7, display: '7 mins' },
  { value: 8, display: '8 mins' },
  { value: 9, display: '9 mins' },
  { value: 10, display: '10 mins' },
  { value: 15, display: '15 mins' },
  { value: 20, display: '20 mins' },
  { value: 30, display: '30 mins' },
  { value: 45, display: '45 mins' },
  { value: 60, display: '1 hr' },
  { value: 90, display: '1 1/2 hrs' },
  { value: 120, display: '2 hr' },
];

export const minsGraceToReadable = [
  { value: 1, display: '1 min' },
  { value: 2, display: '2 mins' },
  { value: 3, display: '3 mins' },
  { value: 4, display: '4 mins' },
  { value: 5, display: '5 mins' },
  { value: 6, display: '6 mins' },
  { value: 7, display: '7 mins' },
  { value: 8, display: '8 mins' },
  { value: 9, display: '9 mins' },
  { value: 10, display: '10 mins' },
  { value: 15, display: '15 mins' },
  { value: 20, display: '20 mins' },
  { value: 30, display: '30 mins' },
  { value: 45, display: '45 mins' },
  { value: 60, display: '1 hr' },
  { value: 90, display: '1 1/2 hrs' },
  { value: 120, display: '2 hr' },
  { value: 150, display: '2 hrs 30 mins' },
  { value: 180, display: '3 hrs' },
  { value: 210, display: '3 hrs 30 mins' },
  { value: 240, display: '4 hrs' },  
  { value: 270, display: '4 hrs 30 mins' },
  { value: 300, display: '5 hrs' },
  { value: 330, display: '5 hrs 30 mins' },
  { value: 360, display: '6 hrs' },
  { value: 390, display: '6 hrs 30 mins' },
  { value: 420, display: '7 hrs' },
  { value: 450, display: '7 hrs 30 mins' },
  { value: 480, display: '8 hrs' },
  { value: 510, display: '8 hrs 30 mins' },
  { value: 540, display: '9 hrs' },
  { value: 570, display: '9 hrs 30 mins' },
  { value: 600, display: '10 hrs' },
  { value: 630, display: '10 hrs 30 mins' },
  { value: 660, display: '11 hrs' },
  { value: 690, display: '11 hrs 30 mins' },
  { value: 720, display: '12 hrs' },
];

export const minsToMaxTime = [
  { value: 0, display: 'unlimited' },
  { value: 15, display: '15 mins' },
  { value: 30, display: '30 mins' },
  { value: 45, display: '45 mins' },
  { value: 60, display: '1 hr' },
  { value: 75, display: '1 hr 15 mins' },
  { value: 90, display: '1 hr 30 mins' },
  { value: 105, display: '1 hr 45 mins' },
  { value: 120, display: '2 hrs' },
  { value: 135, display: '2 hrs 15 mins' },
  { value: 150, display: '2 hrs 30 mins' },
  { value: 165, display: '2 hrs 45 mins' },
  { value: 180, display: '3 hrs' },
  { value: 195, display: '3 hrs 15 mins' },
  { value: 210, display: '3 hrs 30 mins' },
  { value: 225, display: '3 hrs 45 mins' },
  { value: 240, display: '4 hrs' },  
  { value: 270, display: '4 hrs 30 mins' },
  { value: 300, display: '5 hrs' },
  { value: 330, display: '5 hrs 30 mins' },
  { value: 360, display: '6 hrs' },
  { value: 390, display: '6 hrs 30 mins' },
  { value: 420, display: '7 hrs' },
  { value: 450, display: '7 hrs 30 mins' },
  { value: 480, display: '8 hrs' },
];

//#region React Imports
import React, { useState, useEffect, useCallback } from 'react';

//service
import AppChargersService from '../../AppChargersService';

//Components
import ChargerProgress from './ChargerProgressBar/ChargerProgress';
import ChargingStatus from './ChargingStatus';
import ChargerSelections from './ChargerSelections';
import ChargerTimeSummary from './ChargerTimeSummary';
import ChargerButton from './ChargerButton';
import { LoadingSpinner } from '../../../../shared';

//hooks
import { usePrevious } from '../../../../shared/hooks/usePrevious';
// import { usePollingHook } from '../../../../shared/hooks/usePollingHook';

//styles
import { DivStyles } from '../../../../SharedStyles';

//helpers
import { connectorConstants } from '../../../../driver/helpers';

const ControlCharger = (props) => {
  const { strings } = AppChargersService;
  const { charger } = props;
  const chargingSessionPollTime = 10000;
  const statusPollTime = 5000;
  const [pollETA, setPollETA] = useState(false);

  //current charging station via props - can change based on url id and redirects
  const [connectors, setConnectors] = useState([]);
  const [selectedPort, setSelectedPort] = useState(
    charger.evses[0].connectors.length === 1
      ? charger.evses[0].connectors[0].id
      : ''
  );
  const [chargerDisabled, setChargerDisabled] = useState(false);
  const [selectedRange, setSelectedRange] = useState(0);
  const [loading, setLoading] = useState(false);
  const [diagnostics, setDiagnostics] = useState(null);
  const [chargingSessionEta, setChargingSessionEta] = useState({});
  const [showSummary, setShowSummary] = useState(false);

  const [isCharging, setIsCharging] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const prevStatus = usePrevious(connectors);

  const handleChargerDiagnostics = async (reset) => {
    setLoading(true);
    if (reset) {
      handleResetAllOnPortChange();
    }
    let res = await AppChargersService.viewChargingStationDiagnostics({
      iotHubDeviceId: charger.iotHubDeviceId,
      port: selectedPort,
    });
    setDiagnostics(res);
    if (res.authenticatedUser && res.authenticatedUser.chargeRangeInfo) {
      setSelectedRange(res.authenticatedUser.chargeRangeInfo.desiredMaxRange);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      prevStatus !== undefined &&
      prevStatus.length > 0 &&
      connectors.length > 0 &&
      selectedPort
    ) {
      if (
        prevStatus[selectedPort - 1].status === 6 &&
        (connectors[selectedPort - 1].status === 1 ||
          connectors[selectedPort - 1].status === 2)
      ) {
        handleResetAllOnPortChange();
        if (selectedPort) {
          handleChargerDiagnostics(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevStatus, connectors, selectedPort]);

  useEffect(() => {
    if (selectedPort && diagnostics && !diagnostics.authenticatedUser) {
      handleChargerDiagnostics(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPort, isCharging, isFinished]);

  const handleResetAllOnPortChange = () => {
    setIsCharging(false);
    setShowSummary(false);
    setChargingSessionEta({});
    setDiagnostics(null);
    setSelectedRange('');
    setPollETA(false);
    setIsFinished(false);
  };

  const fetchStationStatus = async () => {
    let res = await AppChargersService.getChargingStationStatus(
      charger.iotHubDeviceId
    );
    setConnectors(res.connectorStatuses);

    let disabled = res.connectorStatuses.every(
      (c) => c.status === connectorConstants.Unavailable
    );
    if (disabled && res.availabilityStatus === connectorConstants.Unavailable) {
      setChargerDisabled(true);
    }
  };

  useEffect(() => {
    fetchStationStatus();
    let statusPoll = setInterval(fetchStationStatus, statusPollTime);
    return () => {
      clearInterval(statusPoll);
    };
  }, []);

  useEffect(() => {
    if (connectors.length > 0) {
      connectors.forEach((c) => {
        if (
          c.connectorId === parseInt(selectedPort) &&
          c.status === connectorConstants.Charging
        ) {
          setIsCharging(true);
          setIsButtonDisabled(false);
        }

        if (
          prevStatus !== undefined &&
          prevStatus.length > 0 &&
          prevStatus[c.connectorId - 1].status === 2 &&
          c.status === 1
        ) {
          setIsCharging(false);
          setIsButtonDisabled(false);
          setPollETA(false);
        }
        if (
          prevStatus !== undefined &&
          prevStatus.length > 0 &&
          prevStatus[c.connectorId - 1].status === 3 &&
          c.status === 2
        ) {
          setIsCharging(false);
          setIsButtonDisabled(false);
          setPollETA(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors]);

  useEffect(() => {
    if (selectedPort) {
      handleChargerDiagnostics(selectedPort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charger, selectedPort]);

  //Handle Selection - will trigger the Diagnostics api check
  const handleSelections = useCallback((key, value) => {
    if (key === 'port') {
      setSelectedPort(value);
    }
    if (key === 'kWh') {
      setSelectedRange(value);
    }
  }, []);

  //Disable and Enable Start now button
  useEffect(() => {
    if (
      selectedRange > 0 &&
      selectedPort &&
      !chargerDisabled &&
      Object.keys(chargingSessionEta).length === 0 &&
      !isCharging
    ) {
      setIsButtonDisabled(false);
    } else {
      if (
        isCharging &&
        Object.keys(chargingSessionEta).length > 0 &&
        !chargerDisabled
      ) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPort, selectedRange, isCharging, chargerDisabled]);

  const handleStartStop = async () => {
    try {
      setIsButtonDisabled(true);
      setLoading(true);

      if (!isCharging) {
        await AppChargersService.startChargingSession({
          iotHubDeviceId: charger.iotHubDeviceId,
          userId: diagnostics.authenticatedUser
            ? diagnostics.authenticatedUser.userId
            : null,
          vehicleId: '',
          connectorId: selectedPort,
          couponId: null,
          csoTestSession: true,
          allowAutoPayment: false, //will change to state if enable funds returns
          chargeRangeInfo: {
            desiredMaxRange: selectedRange,
            startRangeOfVehicle: 0,
            maxRangeOfVehicle: 500,
            vehicleBatteryCapacity: 120,
          },
        });
        if (!diagnostics.authenticatedUser) {
          handleChargerDiagnostics(false);
        }
        setIsCharging(true);
        setPollETA(true);
      } else {
        await AppChargersService.stopChargingSession({
          iotHubDeviceId: charger.iotHubDeviceId,
          vehicleId: diagnostics.vehicle,
          userId: diagnostics.authenticatedUser
            ? diagnostics.authenticatedUser.userId
            : null,
        });
        setIsCharging(false);
        setIsFinished(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsCharging(false);
      setIsFinished(false);
      setIsButtonDisabled(false);
      console.log(err);
    }
  };

  /**
   * Keeps the ETA information up-to-date.
   * Called periodically by setInterval during a charging session.
   */
  const updateSessionEta = async () => {
    try {
      let maxRange =
        diagnostics &&
        diagnostics.authenticatedUser &&
        diagnostics.authenticatedUser.chargeRangeInfo
          ? diagnostics.authenticatedUser.chargeRangeInfo.maxRangeOfVehicle
          : selectedRange > 0
          ? selectedRange
          : 500;

      let startRange =
        diagnostics &&
        diagnostics.authenticatedUser &&
        diagnostics.authenticatedUser.chargeRangeInfo
          ? diagnostics.authenticatedUser.chargeRangeInfo.startRangeOfVehicle
          : 0;

      let desiredRange =
        diagnostics &&
        diagnostics.authenticatedUser &&
        diagnostics.authenticatedUser.chargeRangeInfo
          ? diagnostics.authenticatedUser.chargeRangeInfo.desiredMaxRange
          : selectedRange;

      let batteryCap =
        diagnostics &&
        diagnostics.authenticatedUser &&
        diagnostics.authenticatedUser.chargeRangeInfo
          ? diagnostics.authenticatedUser.chargeRangeInfo.vehicleBatteryCapacity
          : 120;

      let cnn;
      if(connectors.length === 0) {
        const res = await AppChargersService.getChargingStationStatus(
          charger.iotHubDeviceId
        );
        cnn = res.connectorStatuses;
      }
      else {
        cnn = connectors;
      }
      //get check the active charging vehicle is there before calling ETA api
      cnn.map(async (status) => {
        if (status.connectorId === parseInt(selectedPort)) {
          try {
            let res = await AppChargersService.getChargingSessionEta({
              iotHubDeviceId: charger.iotHubDeviceId,
              desiredMaxRange: desiredRange,
              startRangeOfVehicle: startRange,
              maxRangeOfVehicle: maxRange,
              vehicleBatteryCapacity: batteryCap,
              csoTestSession: true,
              userId: diagnostics.authenticatedUser
                ? diagnostics.authenticatedUser.userId
                : null,
            });
            setChargingSessionEta(res);
            if (status.status === connectorConstants.Finishing) {
              setShowSummary(true);
            }
            if (res.isChargingComplete) {
              setPollETA(false);
              setIsFinished(true);
            }
          } catch (err) {
            setPollETA(false);
            setIsFinished(true);
            setShowSummary(true);
          }
        }
      });
    } catch (err) {
      console.log('Oh no', err.data.response);
      setPollETA(false);
      setIsFinished(true);
      setShowSummary(true);
    }
  };

  useEffect(() => {
    if (
      diagnostics &&
      diagnostics.authenticatedUser &&
      (charger.evses[0].connectors[selectedPort - 1].status === 3 ||
        charger.evses[0].connectors[selectedPort - 1].status === 6)
    ) {
      updateSessionEta();
      setPollETA(true);
    }
    // eslint-disable-next-line
  }, [diagnostics, charger]);

  // /**
  //  * Toggle ETA polling on and off depending on the
  //  * existence of a charging session for the current
  //  * user.
  //  */
  useEffect(() => {
    if (chargingSessionEta.isChargingComplete) {
      setPollETA(false);
      setIsFinished(true);
    }
  }, [chargingSessionEta.isChargingComplete]);

  useEffect(() => {
    let pollingTimer;
    if (pollETA) {
      console.log('triggered');
      pollingTimer = setInterval(updateSessionEta, chargingSessionPollTime);
    } else {
      clearInterval(pollingTimer);
    }
    return () => {
      clearInterval(pollingTimer);
    };
    // eslint-disable-next-line
  }, [pollETA]);

  return (
    <DivStyles>
      {loading && <LoadingSpinner />}
      <ChargerSelections
        strings={strings}
        charger={charger}
        diagnostics={diagnostics}
        selectedPort={selectedPort}
        selectedRange={selectedRange}
        isCharging={isCharging}
        isFinished={isFinished}
        handleSelections={handleSelections}
      />
      <ChargingStatus
        strings={strings}
        charger={charger}
        connectors={connectors}
        selectedPort={selectedPort}
      />
      <ChargerProgress
        strings={strings}
        charger={charger}
        chargingSessionEta={chargingSessionEta}
        diagnostics={diagnostics}
        isCharging={isCharging}
        selectedRange={selectedRange}
        handleStartStop={handleStartStop}
      />
      <ChargerTimeSummary
        strings={strings}
        charger={charger}
        chargingSessionEta={chargingSessionEta}
        showSummary={showSummary}
        diagnostics={diagnostics}
      />
      <ChargerButton
        charger={charger}
        chargerDisabled={chargerDisabled}
        isCharging={isCharging}
        isFinished={isFinished}
        showSummary={showSummary}
        strings={strings}
        isDisabled={isButtonDisabled}
        handleStartStop={handleStartStop}
      />
    </DivStyles>
  );
};

export default ControlCharger;

import React from 'react';

//services
import AppPricingSchedulesService from '../AppPricingSchedulesService';

//global components
import SubscriptionAccess from '../../OperatorShared/SubscriptionAccess';
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';

//styles
import {
  InputStyles,
  FormContainerStyles,
  DivStyles,
  LabelStyles,
  SelectStyles,
  TitleStyles,
} from '../../../SharedStyles';

//helpers
import { minsGraceToReadable, minsToMaxTime } from '../helpers';

const FormHeaderComponent = (props) => {
  const { strings } = AppPricingSchedulesService;

  const passData = (e) => {
    if (
      (e.target.name !== 'name' && e.target.value === 'on') ||
      (e.target.name !== 'name' && e.target.value === 'off')
    ) {
      props.handleChange(e.target.name, e.target.checked);
    } else {
      props.handleChange(e.target.name, e.target.value);
    }
  };

  return (
    <>
      <FormContainerStyles padding='10px 10px 20px' borderBottom>
        <InputStyles
          required
          type='text'
          name='name'
          onChange={passData}
          value={props.profile.name || ''}
          placeholder={strings.placeholderName}
        />
      </FormContainerStyles>

      <FormContainerStyles
        display='block'
        padding='10px 10px 20px'
        borderBottom>
        <TitleStyles size='14px' weight='600'>
          {strings.defaultRate}
        </TitleStyles>
        <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
          <LabelStyles margin='0 10px 0 0'>{strings.supportsLandingPagePayments}</LabelStyles>
          <ToggleSwitch
            margin='0 10px 0 5px'
            name='defaultSupportsLandingPagePayments'
            handleChange={passData}
            // disabled={props.adminPermission}
            checked={props.profile.defaultSupportsLandingPagePayments}
          />
        </DivStyles>
        <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
          <LabelStyles margin='0 10px 0 0'>{strings.rate}</LabelStyles>
          $
          <InputStyles
            width='50px'
            margin='0 10px 0 5px'
            type='number'
            step={props.profile.defaultRateType === 'kWh' ? '0.001' : '0.01'}
            min={props.profile.defaultRateType === 'kWh' ? '0.000' : '0.01'}
            name='defaultRate'
            onChange={passData}
            value={props.profile.defaultRate || ''}
            placeholder={strings.placeholderRate}
            required={props.profile.defaultRateType !== 'free'}
          />
          /{' '}
          <SelectStyles
            width='95px'
            margin='0 5px 0 10px'
            onChange={passData}
            required
            name='defaultRateType'
            value={props.profile.defaultRateType || ''}>
            <option disabled value=''>
              {strings.placeholderType}
            </option>
            {strings.types.map((type) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </SelectStyles>
        </DivStyles>
        <SubscriptionAccess levels={[3]}>
          <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
            <LabelStyles margin='0 10px 0 0'>{strings.minAmt}</LabelStyles>
            $
            <InputStyles
              width='50px'
              margin='0 10px 0 5px'
              type='number'
              step={'0.01'}
              min={'0.00'}
              name='defaultMinAmount'
              onChange={passData}
              value={props.profile.defaultMinAmount || ''}
            />
          </DivStyles>
          <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
            <LabelStyles margin='0 10px 0 0' minWidth='75px'>
              {strings.maxTime}
            </LabelStyles>
            <SelectStyles
              width='95px'
              margin='0'
              onChange={passData}
              required
              name='defaultMaxTime'
              value={props.profile.defaultMaxTime || 0}>
              {minsToMaxTime.map((type) => {
                return (
                  <option key={type.value} value={type.value}>
                    {type.display}
                  </option>
                );
              })}
            </SelectStyles>
          </DivStyles>
        </SubscriptionAccess>
      </FormContainerStyles>

      <SubscriptionAccess levels={[3]}>
        <FormContainerStyles
          display='block'
          padding='10px 10px 20px'
          borderBottom>
          <TitleStyles size='14px' weight='600'>
            {strings.idleOptions}
          </TitleStyles>
          <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
            <LabelStyles margin='0 10px 0 0'>{strings.defaultIdle}</LabelStyles>
            $
            <InputStyles
              width='50px'
              margin='0 10px 0 5px'
              type='number'
              required={props.profile.defaultIdleFeeType !== 'none'}
              step={
                props.profile.defaultIdleFeeType === 'kWh' ? '0.001' : '0.01'
              }
              min={
                props.profile.defaultIdleFeeType === 'kWh' ? '0.000' : '0.01'
              }
              name='defaultIdleFee'
              placeholder={strings.placeholderRate}
              value={props.profile.defaultIdleFee || ''}
              onChange={passData}
            />
            /{' '}
            <SelectStyles
              width='95px'
              margin='0 10px'
              onChange={passData}
              className='dropdown'
              name='defaultIdleFeeType'
              value={props.profile.defaultIdleFeeType || 'none'}>
              {strings.idleTypes.map((type) => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              })}
            </SelectStyles>
            <SelectStyles
              width='100px'
              margin='0'
              onChange={passData}
              className='dropdown'
              name='defaultGracePeriod'
              required={props.profile.defaultIdleFeeType !== 'none'}
              value={props.profile.defaultGracePeriod || ''}>
              <option value=''>{strings.gracePlaceholder}</option>
              {minsGraceToReadable.map((time) => {
                return (
                  <option key={time.value} value={time.value}>
                    {time.display}
                  </option>
                );
              })}
            </SelectStyles>
          </DivStyles>
        </FormContainerStyles>
      </SubscriptionAccess>
    </>
  );
};

export default FormHeaderComponent;
